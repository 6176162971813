import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import enFile from '../assets/i18n/translations/english.json'
import vnFile from '../assets/i18n/translations/vietnamese.json'

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      en: {
        translation: enFile,
      },
      vi: {
        translation: vnFile,
      },
    },
    lng: 'vi',

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  })

export default i18n
