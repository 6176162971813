/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

import './src/styles/global.scss'
import TagManager from 'react-gtm-module'
import './src/utils/i18n'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export const onClientEntry = () => {
  const tagManagerArgs = {
    gtmId: process.env.ACCOUNT_GTM_ID,
  }

  TagManager.initialize(tagManagerArgs)
}
